<template>
  <a-modal :visible="visible" title="添加自定义车图" destroyOnClose :maskClosable="false"  @ok="handleOk()" @cancel="handleCancel">
      <a-form
        ref="form"
        :model="formState"
        name="form"
        :validateOnRuleChange="false"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
        autocomplete="off"
        scrollToFirstError
      >
        <a-form-item
          label="车型"
          name="carType"
          :rules="[{ required: true, message: '选择车型' }]"
        >
          <a-select
            v-model:value="formState.carType"
            style="width: 100%"
          >
            <a-select-option value="m3" label="Model 3">Model 3</a-select-option>
            <a-select-option value="my" label="Model Y">Model Y</a-select-option>
            <a-select-option value="ms" label="Model S">Model S</a-select-option>
            <a-select-option value="mx" label="Model X">Model X</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="前视角"
          name="quickView1"
        >
          <GigaUpload v-model:value="formState.quickView1" name="quickView1" :imageUrl="formState.quickView1Url" @setImg="setImg($event, 0)"  />
        </a-form-item>
        <a-form-item
          label="侧视角"
          name="quickView2"
        >
          <GigaUpload v-model:value="formState.quickView2" name="quickView2" :imageUrl="formState.quickView2Url" @setImg="setImg($event, 1)"  />
        </a-form-item>
      </a-form>
    </a-modal>
</template>
<script>
import { ref, watch } from 'vue';
import GigaUpload from '../GigaUpload.vue'
export default {
  name: 'OutlookDiyForm',
  components: {
    GigaUpload
  },
  props: ['visible', 'formData'],
  setup(props) {
    const formState = ref(props.formData)
    watch(() => props.formData, (newVal) => formState.value = newVal)
    return {
      formState,
      form: ref(null),
    };
  },
  
  methods: {
    setImg(e, index) {
      let img = e[0]
      let formState = this.formState
      formState[`quickView${index+1}`] = img.id
      formState[`quickView${index+1}Url`] = img.url
      this.formState = JSON.parse(JSON.stringify(formState))
    },
    handleOk() {
      this.form.validateFields().then(res => {
        console.log(res)
        res.quickView = [{
          viewType: '前视角',
          imageId: res.quickView1
        }, {
          viewType: '侧视角',
          imageId: res.quickView2
        }]
        // res.displayOrder = res.displayOrder ? parseInt(res.displayOrder) : 0
        let formState = Object.assign(this.formState, res)
        // formState.coverImageId = formState.logoId || formState.logo.id
        this.$emit('formOk', formState)
      }).catch(err => {
        console.error(err)
        this.$message.error(err.errorFields[0].errors[0])
      })
    },
    handleCancel() {
      this.$emit('formCancel')
    },
  },

}
</script>