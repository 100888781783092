<template>
  <div>
    <div class="operations">
      <div style="flex:1"></div>
      <a-button @click="showForm = true">添加</a-button>
    </div>
    <GigaTable :gData="dataSource" :gColumns="columns" :gInnerColumns="innerColumns" />
    <VehicleWheelForm :visible="showForm" :formData="formState" @formCancel="handleCancel" @formOk="handleOk" /> 
    <OutlookDiyForm :visible="showOutlookDiyForm" :formData="formState" @formCancel="handleCancel" @formOk="handleOk($event, 'outlook')" /> 
  </div>
</template>
<script>
import { getOutlookDiyWheel, editWheel, editOutlookDiyWheel } from '../http/api'
import GigaTable from '../components/GigaTable.vue'
import VehicleWheelForm from '../components/form/VehicleWheelForm'
import OutlookDiyForm from '../components/form/OutlookDiyForm'

export default {
  components: {
    GigaTable,
    VehicleWheelForm,
    OutlookDiyForm
  },
  data() {
    return {
      showOutlookDiyForm: false,
      showForm: false,
      formState: {},
      dataSource: [],
      columns: [{
        title: 'id',
        dataIndex: 'id',
        key: 'id',
        width: 80
      }, {
        title: '名称',
        dataIndex: 'name',
        key: 'name'
      }, {
        title: '尺寸',
        dataIndex: 'size',
        key: 'size'
      }, {
        title: '颜色',
        dataIndex: 'color',
        key: 'color'
      }, {
        title: '车型',
        dataIndex: 'carType',
        key: 'carType'
      }, {
        title: '排序',
        dataIndex: 'displayOrder',
        key: 'displayOrder'
      }, {
        title: '是否发布',
        dataIndex: 'isPublished',
        key: 'isPublished',
        customRender: val => <span v-html={val.value ? '已发布' : '未发布'} />
      }, {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        width: 220,
        customRender: (val) => {
          return (
            <div>
              <a-dropdown trigger={['click']} overlay={(
                <a-menu>
                  <a-menu-item onClick={() => this.editWheel(val.record)}>
                    <span>编辑</span>
                  </a-menu-item>
                  <a-menu-item onClick={() => this.editWheel(val.record, 'publish')}>
                    <span v-html={val.record.isPublished ? '取消发布' : '发布'}></span>
                  </a-menu-item>
                  <a-menu-item onClick={() => this.editWheel(val.record, 'del')}>
                    <span>删除</span>
                  </a-menu-item>
                </a-menu>
              )}>
                <a-button>操作</a-button>
              </a-dropdown>
              <a-button onClick={() => this.addOutlookDiy(val.record)} style="margin-left:10px">添加轮毂图</a-button>
            </div>
          )
        }
      }],

      innerColumns: [{
        title: 'id',
        dataIndex: 'id',
        key: 'id',
        width: 80
      }, {
        title: '车型',
        dataIndex: 'carType',
        key: 'carType'
      }, {
        title: '前视角',
        dataIndex: 'quickView',
        key: 'quickView1',
        customRender: val => {
          return <img src={val.value && val.value[0] && val.value[0].image && val.value[0].image.url || ''} style="width: 40px" />
        }
      }, {
        title: '侧视角',
        dataIndex: 'quickView',
        key: 'quickView2',
        customRender: val => {
          return <img src={val.value && val.value[1] && val.value[1].image && val.value[1].image.url || ''} style="width: 40px" />
        }
      }, {
        title: '是否发布',
        dataIndex: 'isPublished',
        key: 'isPublished',
        customRender: val => <span v-html={val.value ? '已发布' : '未发布'} />
      }, {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        width: 200,
        customRender: (val) => {
          return (
            <div>
              <a-dropdown trigger={['click']} overlay={(
                <a-menu>
                  <a-menu-item onClick={() => this.editOutlookDiyWheel(val.record)}>
                    <span>编辑</span>
                  </a-menu-item>
                  <a-menu-item onClick={() => this.editOutlookDiyWheel(val.record, 'publish')}>
                    <span v-html={val.record.isPublished ? '取消发布' : '发布'}></span>
                  </a-menu-item>
                  <a-menu-item onClick={() => this.editOutlookDiyWheel(val.record, 'del')}>
                    <span>删除</span>
                  </a-menu-item>
                </a-menu>
              )}>
                <a-button>操作</a-button>
              </a-dropdown>
            </div>
          )
        }
      }]
    }
  },
  created() {
    this.getVehicleWheel()
  },
  methods: {
    setImg(e, type) {
      let img = e[0]
      let formState = this.formState
      formState[type] = img.url
      this.formState = JSON.parse(JSON.stringify(formState))
    },
    handleOk(formState, type) {
      if (type == 'outlook') {
        console.log(formState)
        editOutlookDiyWheel({variables: formState}).then(res => {
          console.log(res)
          this.handleCancel()
          this.getVehicleWheel()
        }).catch(err => {
          console.log(err)
        })
      } else {
        editWheel({variables: formState}).then(res => {
          console.log(res)
          this.handleCancel()
          this.getVehicleWheel()
        }).catch(err => {
          console.log(err)
        })
      }
    },
    editOutlookDiyWheel(item, type) {
      let record = JSON.parse(JSON.stringify(item))
      console.log(type)
      if (type == 'del') {
        this.handleOk({id: record.id, isDeleted: true}, 'outlook')
      } else if (type == 'publish') {
        this.handleOk({id: record.id, isPublished: !record.isPublished}, 'outlook')
      } else { 
        record.quickView1 = record.quickView && record.quickView[0] && record.quickView[0].image && record.quickView[0].image.id || ''
        record.quickView1Url = record.quickView && record.quickView[0] && record.quickView[0].image && record.quickView[0].image.url || ''
        record.quickView2 = record.quickView && record.quickView[1] && record.quickView[1].image && record.quickView[1].image.id || ''
        record.quickView2Url = record.quickView && record.quickView[1] && record.quickView[1].image && record.quickView[1].image.url || ''
        record.carType = `m${(record.carType).split(' ')[1].toLocaleLowerCase()}`
        this.formState = record
        this.showOutlookDiyForm = true
      }
    },
    editWheel(item, type) {
      let record = JSON.parse(JSON.stringify(item))
      if (type == 'del') {
        this.handleOk({id: record.id, isDeleted: true})
      } else if (type == 'publish') {
        this.handleOk({id: record.id, isPublished: !record.isPublished})
      } else {
        console.log(record)
        record.carType = `m${(record.carType).split(' ')[1].toLocaleLowerCase()}`
        this.formState = record
        
        this.showForm = true
      }
    },
    addOutlookDiy(record) {
      console.log(record)
      let formState = this.formState
      formState.wheelId = record.id
      this.showOutlookDiyForm = true
    },
    handleCancel() {
      this.showForm = false
      this.showOutlookDiyForm = false
      this.formState = {}
    },
    getVehicleWheel() {
      getOutlookDiyWheel().then(res => {
        if (res.errors) {
          this.$message.error(res.errors[0].message)
          return
        }
        console.log(res)
        let wheelItems = res.data.outlookDiy.wheelItems
        let vehicleWheels = res.data.outlookDiy.vehicleWheels
        // let outlookDiyItems = res.data.outlookDiy.items
        vehicleWheels.forEach(e => {
          wheelItems.forEach(el => {
            if (el.wheel?.id == e.id) {
              e.innerData = e.innerData ? e.innerData : []
              e.innerData.push(el)
            }
          })
        })
        let dataSource = vehicleWheels
        console.log(dataSource)
        this.dataSource = dataSource
      })
    }
  },
}
</script>
<style lang="scss" scoped>
  
</style>